import React from 'react';

// styles
import useStyles from '../../../assets/styles/jss/pages/exploreHome/bannerBySeasonStyle'

//material
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

//assets/images
import { Hidden, useMediaQuery } from '@material-ui/core';
import ResponsiveContainer from '../../../shared/components/ResponsiveContainer/ResponsiveContainer';

const BannerBySeasonVideo = () => {
    const classes = useStyles();
    const isXs = useMediaQuery(theme => theme.breakpoints.down('xs'));  
    const src = isXs ? "/videos/banner-xs.mp4" : "/videos/banner.mp4";
    const poster = isXs ? "/videos/banner-xs.jpg" : "/videos/banner.jpg";

    return (
        <Box id="banner-video-root" className={classes.root} sx={{height:{xs: '609px !important',sm: '348px !important', md: '650px !important'}}}>
            
            <Hidden xsDown>
                <video className={classes.bgVideo} autoPlay loop muted poster={poster}>
                    <source src={src} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </Hidden>
            <Hidden smUp>
                <Box className={classes.bgImageVideo}/>
            </Hidden>

            <ResponsiveContainer className={classes.responsiveContainer}>
                <Box className={classes.videoContent}>
                    <Box textAlign='inherit' display='flex' flexDirection='column' sx={{gridGap:{xs: '10px',sm: '16px', md: '32px'}}} width="500px" className={classes.videoContentTitle}>
                        <Typography variant='h2' className='text-white'>
                        ¡Aprovecha y empieza tu programa hoy!
                        </Typography>
                        <Typography variant='h3' className='text-orange-300'>
                        Utiliza el cupón: BUENFIN
                        </Typography>
                    </Box>

                    <Box className={classes.actions}>
                        <Button variant='contained' size='large' style={{minWidth: 200}} href="https://www.talisis.com/membresias?utm_campaign=Buen%20Fin&utm_source=banner">Empieza ya</Button>
                    </Box>
                </Box>
            </ResponsiveContainer>
        </Box>
    );
};

export default BannerBySeasonVideo;