import React from 'react';

// @material-ui
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { Chip, Hidden, Icon } from '@material-ui/core';

//
import useStyles from '../../../../../assets/styles/jss/pages/courses/programs/subjectsPerQuarterStyle'

// @sdk-point
import { STATUS_COURSES } from "@sdk-point/talisis";
import CardProgramListExpand from '../../../SyncProgram/CardProgramList/CardProgramListExpand';
import OptativeCourseCard from '../../../../../design-system/components/Cards/OptativeCourseCard/OptativeCourseCard';
import clsx from 'clsx';

const LABEL_FINISHED = 'Finalizado';
const LABEL_PROGRESS = 'En progreso'
const LABEL_PENDING = 'Pendiente'

const STATUS = {
    [STATUS_COURSES.IN_PROGRESS]: LABEL_PROGRESS,
    [STATUS_COURSES.COMPLETED]: LABEL_FINISHED,
    [STATUS_COURSES.PENDING]: LABEL_PENDING,
}

const SubjectsPerQuarter = ({data, credits, userMembership, onClickSelect}) => {
    const classes = useStyles();
    const [expanded, setExpanded] = React.useState(false);
    const areaPriorityActive = data.find(it => it.active).area_priority;

    React.useEffect(() =>{
        if(data.length){
            setExpanded(data.find(it => it.active).id);
        }
    }, [data])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const ChipStatus = ({status}) => {

        if(status === STATUS_COURSES.PENDING) return null;

        const completed = status == STATUS_COURSES.COMPLETED;
        const icon = completed ? "ri-checkbox-circle-fill text-success-300" : 'ri-play-circle-fill text-blue-300';

        return <Chip 
            icon={<Icon className={`font-size-16 bg-transparent ml-0 ${icon}`}/>} 
            label={`${STATUS[status]}`} 
            className={`${classes.chipStatus} ${completed ? 'text-success-300' : 'text-blue-300'}`}
        />
    }

    if(!data.length){
        return null;
    }
  
    return (
        <Box className={classes.root}>
            {
                data.map((grade, index) =>
                    <Accordion key={`panel${index}`} expanded={expanded === grade.id} onChange={handleChange(grade.id)} className={classes.accordion} elevation={0}>
                        <AccordionSummary
                            expandIcon={<Icon className="ri-arrow-up-s-line text-white font-size-24 p-0" />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                            className={classes.accordionSummary}
                        >
                            <Typography variant='h4'>{grade.label}</Typography>
                            <ChipStatus status={grade.status}/>
                            {   grade.hasNewSubject && <>
                                    <Hidden xsDown>
                                        <Chip label="Nueva materia disponible" 
                                            icon={<Icon className='font-size-16 ri-flag-fill bg-transparent'/>} 
                                            className={clsx('ml-auto', classes.chipNewSubject)} /> 
                                    </Hidden>
                                    <Hidden smUp>
                                        <Chip label="Nueva materia" 
                                            icon={<Icon className='font-size-16 ri-flag-fill bg-transparent'/>} 
                                            className={clsx('ml-auto', classes.chipNewSubject)} /> 
                                    </Hidden>
                                </>
                            } 
                        </AccordionSummary>
                        <AccordionDetails className="p-0" style={{background: 'transparent'}}>
                            <Grid container spacing={2}>
                                {grade.courses.map((courseItem, index) => 
                                    <Grid item xs={12} sm={6} md={6} lg={4} key={index + 'CPL'} className={classes.gridCard}>
                                        <CardProgramListExpand {...courseItem}
                                            // status={grade.status} 
                                            // type_program={program.type_program} 
                                            tagPeriodo={courseItem.tagLabel}
                                            remaining_credits={credits} 
                                            // no_available={courseItem?.no_available} 
                                            cuatrimestre={areaPriorityActive} 
                                            userMembership={userMembership}
                                        />
                                    </Grid>
                                )}
                                {grade.optatives.map((optativeItem, optIndex) => 
                                    <Grid item xs={12} sm={6} md={6} lg={4} key={`opt-course-card-${optIndex}`}>
                                        <OptativeCourseCard 
                                            textPrimary={optativeItem}
                                            onClick={onClickSelect}
                                            disabled={!grade.enableOptatives}
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )
            }
        </Box>
    );
};

export default SubjectsPerQuarter;